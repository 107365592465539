export default {
  methods: {
    // Muestra el Dialog con las opciones según los puntos disponibles
    showDialogOptions() {
      if (this.points.points.points >= this.product.value) {
        this.DialogWithOptions = true;
      } else {
        document.getElementById("buttonShowOptions").classList.add("disabled");
        this.lackOfPoints = true;
        this.displayRestaOptions();
      }
    },

    // Devuelve un string con el resultado de los puntos disponibles menos el valor del producto
    displayRestaOptions() {
      const restaPuntos = this.points.points.points - this.product.value;
      return Math.abs(restaPuntos);
    },

    // Devuelve un string según el stock disponible
    stockMessage() {
      const stockNumber = this.product.stock;
      if (stockNumber == 0) {
        return this.$t("BuyingProcess.products.no-stock");
      }
      if (stockNumber <= 10) {
        return this.$t("BuyingProcess.products.last-units");
      }
      if (stockNumber > 10) {
        return this.$t("BuyingProcess.products.stock");
      }
    },
    // Elimina el dato una vez se accede a otro ProductPage. TODO: Desktop
    deleteInfo() {
      Object.assign(this.$data, this.$options.data());
    },

    // Cierra el Dialog
    close() {
        this.$emit("update:dialog", false);
      },
  },
};
